var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "practicearea--accordion",
      class: { "o-bg": _vm.backgroundColor, color: _vm.textColor },
      style: _vm.style,
    },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                {
                  staticClass: "practicearea--accordion__block",
                  attrs: { md: 10 },
                },
                [
                  _c(
                    "Heading",
                    {
                      staticClass: "practicearea--global__title",
                      attrs: { level: 2 },
                    },
                    [_vm._v(_vm._s(_vm.model.title))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.model.content.content, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "accordion-item practicearea--accordion__item",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "practicearea--accordion__header",
                      on: {
                        click: function ($event) {
                          return _vm.toggleItem(index)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "div",
                          { staticClass: "practicearea--accordion__header" },
                          [
                            _c("XhtmlField", {
                              attrs: { items: item.heading },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("svg", { staticClass: "toggle-sign" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href":
                              _vm.activeIndex === index ? "#minus" : "#plus",
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "accordion" } }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeIndex === index,
                            expression: "activeIndex === index",
                          },
                        ],
                        staticClass: "practicearea--accordion__content",
                      },
                      [_c("XhtmlField", { attrs: { items: item.content } })],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }