<template>
<main class="c-start-page">
    <Hero v-if="model.heroImage && model.heroImage.url" :buttonText="model.heroLinkText" :buttonText2="model.heroLink2Text" :title="model.title" :subtitle="model.subtitle" :heroImage="model.heroImage && model.heroImage.url" :url="model.heroLink && model.heroLink.url" :url2="model.heroLink2 && model.heroLink2.url" :changeTitleColor="model.changeTitleColor" :changeLinkColor="model.changeLinkColor"/>
    <Container>
        <KeyFigures class="c-start-page__key-figures" v-if="(model.keyFigures && notEmptyObject(model.keyFigures.content))" v-bind:model="model.keyFigures" v-epi-edit="'KeyFigures'" />
        <h2 class="u-add-colored-period">{{getLabel('startPage', 'whatWeDo')}}</h2>
        <Row>
            <Column :md="14" v-if="(model.leftPageList && model.leftPageList.content) || isEditable" v-epi-edit="'leftPageList'">
                <PageListBlock v-bind="model.leftPageList && model.leftPageList.content"/>
            </Column>
            <Column :md="6" :offsetMd="4" class="c-start-page__right-content">
                <PageListBlock v-epi-edit="'RightProductList'" v-bind="model.rightProductList && model.rightProductList.content" class="c-start-page__product-list"/>
                <PageListBlock v-epi-edit="'SecondRightProductList'" v-bind="model.secondRightProductList && model.secondRightProductList.content" class="c-start-page__product-list"/>
            </Column>
        </Row>
        <!-- eslint-disable-next-line no-irregular-whitespace -->
        <SingleTeaserBlock leftEdit="leftCampaign" rightEdit="rightCampaign" v-if="(model.rightCampaign && notEmptyObject(model.rightCampaign.content)) ||isEditable" :extraTeaser="model.leftCampaign && model.leftCampaign.content" v-bind="model.rightCampaign && model.rightCampaign.content"/>
        <HeroBlock v-epi-edit="'FullWidthHero'" v-if="notEmptyObject(model.fullWidthHero && model.fullWidthHero.content)" v-bind="model.fullWidthHero && model.fullWidthHero.content "/>
        <NewsBlock v-epi-edit="'NewsBlock'" v-if="notEmptyObject(model.newsBlock.content)" v-spacing="{xs: 'small', sm: 'medium', md: 'large', lg: 'large'}"  v-bind="model.newsBlock.content" />
        <div v-if="model.showGartnerWidget">
        <GartnerWidgetBlock/>
        </div>
        <PageListBlock v-epi-edit="'PageSlider'" v-bind="model.pageSlider && model.pageSlider.content" class="c-start-page__slider" :style="{ 'padding-top' : model.showGartnerWidget ? '32px' : '0px' }" :hidetext=true />
    </Container>
    <!-- eslint-disable-next-line no-irregular-whitespace -->
    <Container isFluid background="beige" v-if="(!model.hidePartners && model.partnersBlock && notEmptyObject(model.partnersBlock.content)) ||isEditable" class=" c-start-page__partners" >
        <Container>
            <Row>
                <Column :offsetMd="2">
                    <PartnersBlock v-bind="model.partnersBlock.content" v-epi-edit="'PartnersBlock'" />
                </Column>
            </Row>
        </Container>
    </Container>
    <Container v-if="(model.bottomRightCampaign && notEmptyObject(model.bottomRightCampaign.content)) || isEditable">
        <SingleTeaserBlock leftEdit="bottomLeftCampaign" rightEdit="bottomRightCampaign" :extraTeaser="model.bottomLeftCampaign && model.bottomLeftCampaign.content" v-bind="model.bottomRightCampaign && model.bottomRightCampaign.content" class="c-start-page__bottom-campaign" />
    </Container>
    <Container>
        <OfficesBlock v-epi-edit="'OfficesBlock'" v-if="notEmptyObject(model.officesBlock.content)" v-bind="model.officesBlock.content"/>
    </Container>
</main>
</template>

<script>
import ContentArea from '@/Scripts/components/ContentArea.vue';
import Hero from '@/Scripts/components/molecules/Hero.vue';
import LanguageSelector from '@/Scripts/components/widgets/LanguageSelector.vue';
import Modal from '@/Scripts/components/widgets/Modal.vue';
import { SHOW_MODAL } from '@/Scripts/store/modules/appContext';
import { mapMutations } from 'vuex';

import PageListBlock from '@/Scripts/components/blocks/PageListBlock/PageListBlock.vue';
import HeroBlock from '@/Scripts/components/blocks/HeroBlock.vue';
import PartnersBlock from '@/Scripts/components/blocks/PartnersBlock.vue';
import OfficesBlock from '@/Scripts/components/blocks/OfficesBlock.vue';
import KeyFigures from '@/Scripts/components/blocks/KeyFiguresBlock.vue';

import Button from '@/Scripts/components/atoms/Button.vue';
import Anchor from '@/Scripts/components/atoms/Anchor.vue';
import Dropdown from '@/Scripts/components/atoms/Dropdown.vue';
import PhoneInput from '@/Scripts/components/atoms/PhoneInput.vue';
import GartnerWidgetBlock from '@/Scripts/components/blocks/GartnerWidgetBlock.vue';


export default {
    components: {
        ContentArea,
        Hero,
        LanguageSelector,
        Modal,
        PartnersBlock,
        OfficesBlock,
        Button,
        Anchor,
        Dropdown,
        PhoneInput,
        PageListBlock,
        HeroBlock,
        KeyFigures,
        GartnerWidgetBlock,
    },
    props: ['model'],
    methods: mapMutations({
        showModal: SHOW_MODAL
    }),
    data: function () {
        return {
            test: null,
            phone: ''
        };
    }
};
</script>

<style lang="scss">
@import '@/Styles/tools/_breakpoints.scss';
@import '@/Styles/tools/_grid-mixin.scss';

.c-start-page {
    &__key-figures {
        margin-top: 3.3rem;
    }
    &__bottom-campaign {
        margin-bottom: 7rem;
    }
    &__partners {
        margin-bottom: 7rem;
    }
    &__slider {
        margin-bottom: 7rem;
        .o-col--breakout__content {
            max-width: 1530px;
        }
    }
    &__product-list {
        position: relative;
        .c-page-list-list {
            &__label {
                 margin-bottom: 1.5em;
                display: inline-block;
                color: $gray;
            }
            &__title, &__intro {
                display: none;
            }
        }
        & + & {
            margin-top: 3rem;
        }
        &:before {
            @include media-md {
                content: '';
                position: absolute;
                top: 0;
                left: -1.5rem;
                height: 100%;
                border-left: 1px solid $orange;
            }
        }
        .c-list__item {
            @include col-padding;

            &-wrapper {
                border-color: $beige !important;
            }
            @include media-sm {
                flex-basis: 50%;
                max-width: 50%;

                &:nth-child(2) {
                    .c-list__item-wrapper {
                        border-top: 1px solid $beige;
                    }
                }
            }
            @include media-md {
                flex-basis: 100%;
                max-width: 100%;

                &:nth-child(2) {
                    .c-list__item-wrapper {
                        border-top: none;
                    }
                }
            }
        }
    }
}
</style>
