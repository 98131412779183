<template>
    <Row class="c-page-list--large">
        <Column :md="6" v-for="(item,index) in items" :key="index" class="c-page-list--large__item">
            <div class="c-page-list--large__content">
                <AspectRatioBox :width="323" :height="193">
                    <ResponsiveImage :src="item.imageUrl" v-bind="imageConfig"/>
                </AspectRatioBox>
                <div class="c-page-list--large__content__text">
                    <Heading isCategory>{{item.label}}</Heading>
                    <Heading :level="5" class="c-page-list--large__heading u-no-margin-top">{{item.title}}</Heading>
                    <v-clamp class="o-large-body" autoresize :max-lines="3" tag="p">{{item.intro}}</v-clamp>
                    <Anchor class="c-page-list--large__link" isFancy :href="item.url" >{{getLabel('global', 'readMore')}}</Anchor>
                </div>
            </div>
        </Column>
    </Row>
</template>

<script>
import AspectRatioBox from '@/Scripts/components/atoms/AspectRatioBox.vue';
import VClamp from 'vue-clamp';
export default {
    props: {
        items: Array,
    },
    components: {
        AspectRatioBox,
        VClamp
    },
    data() {
        return {
            imageConfig: {
                xs: {w: 345, h: 206},
                sm: {w: 642, h: 383},
                md: {w: 323, h: 193},
                lg: {w: 323, h: 193}
            }
        };
    }
};
</script>

<style lang="scss">
.c-page-list--large {
    @include media-md {
        margin-bottom: -2rem;
    }
    &__item {
        margin-bottom: 4rem;

        @include media-md {
            margin-bottom: 2rem;
        }
    }
    &__content {
        background-color: $beige-light;
        height: 100%;
        position: relative;

        &__text {
            padding: 20px;
            padding-bottom: 40px;
        }
    }
    &__link {
        position: absolute;
        bottom: 20px;
    }
}
</style>
