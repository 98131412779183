var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-page-list-list" },
    [
      _c("List", {
        staticClass: "list-margin-top",
        attrs: { items: _vm.items },
      }),
      _vm._v(" "),
      _vm.url
        ? _c("Anchor", { attrs: { href: _vm.url, isFancy: "" } }, [
            _vm._v(_vm._s(_vm.getLabel("global", "viewAll"))),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }