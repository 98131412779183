<template>
    <Row class="c-page-list-slider">
        <BreakoutColumn breakoutSide="right" v-if="items.length > 3 && $screen.breakpoint !=='xs'">
            <HorizontalSlider :items="items" :scroll="2">
                <template v-slot:default="{item}">
                    <PageListSliderItem v-bind="item"/>
                </template>
            </HorizontalSlider>
        </BreakoutColumn>
        <Column v-else-if="$screen.breakpoint !=='xs'" class="c-page-list-slider__noslide-item" :sm="12" :md="8" v-for="(item, index) in items" :key="`${_uid}${index}`">
        <PageListSliderItem v-bind="item"/>
        </Column>
        <NewsComponentList isVertical :items="items" v-if="$screen.breakpoint ==='xs'"/>
    </Row>
</template>

<script>
import HorizontalSlider from '@/Scripts/components/molecules/HorizontalSlider.vue';
import PageListSliderItem from './PageListSliderItem.vue';
import NewsComponentList from '@/Scripts/components/molecules/NewsComponents/NewsComponentList.vue';

export default {
    props: {
        items: Array,
    },
    components: {
        HorizontalSlider,
        NewsComponentList,
        PageListSliderItem
    },
    computed: {
        media() {
            return {
                'is-phone': this.$screen.sm,
                'is-tablet': this.$screen.md,
                'is-desktop': this.$screen.lg
            };
        }
    }
};
</script>

<style lang="scss">
.c-page-list-slider {
    &__label {
        margin-bottom: 1.5em;
        display: inline-block;
        color: $gray;
    }
    &__noslide-item {
        margin-bottom: 1rem;
    }
}
</style>
