<template>
<div class="c-page-list-list">
    <List :items="items" class="list-margin-top"/>
    <Anchor v-if="url" :href="url" isFancy>{{getLabel('global', 'viewAll')}}</Anchor>
</div>
</template>

<script>
import List from '@/Scripts/components/molecules/List.vue';
import Anchor from '@/Scripts/components/atoms/Anchor.vue';

export default {
    props: {
        label: String,
        title: String,
        intro: String,
        introHtml: String,
        items: Array,
        url: String
    },
    components: {
        List,
        Anchor
    }
};
</script>

<style lang="scss">
.c-page-list-list {
    &__label {
       /*  margin-bottom: 1.5em;
        display: inline-block;
        color: $gray; */
    }
}

.list-margin-top {
    margin-top: 1.5em;
}
</style>
