var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.hidetext
        ? _c(
            "Row",
            [
              _c(
                "Column",
                [
                  _vm.label
                    ? _c(
                        "Heading",
                        {
                          staticClass: "c-page-list-list__label",
                          attrs: { isCategory: "" },
                        },
                        [_vm._v(_vm._s(_vm.label))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.title
                    ? _c(
                        "Heading",
                        {
                          staticClass: "c-page-list-list__title u-no-margins",
                          attrs: { level: 4 },
                        },
                        [_vm._v(_vm._s(_vm.title))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.intro && !_vm.introHtml
                    ? _c(
                        "p",
                        { staticClass: "c-page-list-list__intro o-large-body" },
                        [_vm._v(_vm._s(_vm.intro))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.introHtml
                    ? _c("XhtmlField", {
                        staticClass: "o-large-body",
                        attrs: { items: _vm.introHtml },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.viewMode === "Slider"
        ? [
            _c(
              "PageListSlider",
              _vm._b(
                { attrs: { items: _vm.teasers } },
                "PageListSlider",
                _vm.$props,
                false
              )
            ),
          ]
        : _vm.viewMode === "Large"
        ? [
            _c(
              "PageListLarge",
              _vm._b(
                { attrs: { items: _vm.teasers } },
                "PageListLarge",
                _vm.$props,
                false
              )
            ),
          ]
        : _vm.viewMode === "Small"
        ? [
            _c(
              "PageListSmall",
              _vm._b(
                { attrs: { items: _vm.teasers } },
                "PageListSmall",
                _vm.$props,
                false
              )
            ),
          ]
        : [
            _c(
              "PageListList",
              _vm._b(
                { attrs: { items: _vm.teasers } },
                "PageListList",
                _vm.$props,
                false
              )
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }